import "./markedText.scss";
import Circle from "../../components/SVG/Circle";
import Underline from "../../components/SVG/Underline";

import CircleLong from "../../components/SVG/CircleLong";
import UnderlineSmall from "../../components/SVG/UnderlineSmall";

type MTProps = {
  startText?: string;
  circleText?: string | undefined;
  middelText?: string;
  underlinedText?: string;
  endText?: string;
  handwritten?: string;
  className?: string;
  isSmall: boolean;
  center: boolean;
};

const MarkedText = ({
  startText,
  circleText,
  middelText,
  underlinedText,
  endText,
  handwritten,
  isSmall,
  center,
  className,
}: MTProps) => {
  const circleSize = () => {
    if (circleText) {
      if (circleText?.length < 5) {
        return (
          <div className="circle">
            <Circle />
          </div>
        );
      } else {
        return (
          <div className="circle long">
            <CircleLong />
          </div>
        );
      }
    }
  };
  const underlineSize = () => {
    if (underlinedText?.length < 5) {
      return (
        <div className="underline">
          <UnderlineSmall />
        </div>
      );
    } else {
      return (
        <div className="underline">
          <Underline />
        </div>
      );
    }
  };

  return (
    <div
      className={`${className} marked-text ${center ? "center" : ""} ${
        isSmall ? "small" : "big"
      }`}
    >
      {startText && <> &nbsp;{startText}</>}
      {circleText && (
        <span className="circle-text">
          &nbsp;{circleText}
          {circleSize()}
        </span>
      )}
      {middelText && <>{middelText}</>}
      {underlinedText && (
        <span className="underline-text">
          &nbsp;{underlinedText}
          {underlineSize()}
        </span>
      )}
      &nbsp;{endText ? `${endText}` : ""}
      {handwritten ? <span className="handwritten"> {handwritten}</span> : ""}
    </div>
  );
};
export default MarkedText;
